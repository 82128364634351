





























































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";

@Component({
  components: {},
  props: ["table"]
})
export default class TableHeader extends Vue {
  public selectedFormatVersion: string = "";
  public selectedRole: string = "";
  public formatVersions: string[] = [];
  public roles: any[] = [];
  public isError: boolean = false;
  public errorMessage: string = "";

  public beforeMount() {
    this.$props.table.format_version = this.selectedFormatVersion;
  }
  public async mounted() {
    try {
      this.formatVersions = await new GeneratorService().getFormatVersions();
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Fehler beim Laden der Formatversionen.";
    }
    try {
      const rolesArray = await new GeneratorService().getRoles();
      rolesArray.forEach((role: string) => {
        if (role === "NO_ROLE") {
          return this.roles.push(role);
        }
        return this.roles.push(`Prüfende Rolle: ${role}`);
      });
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Fehler beim Laden der prüfenden Rollen.";
    }
  }
}
