








import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/Table.vue";
@Component({
  components: { Table }
})
export default class Home extends Vue {}
