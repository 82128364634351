import axios from "axios";
import _, { findKey } from "lodash";
import { BackendServices } from "./BackendServices";

export default class GeneratorService extends BackendServices {
  public postPdfData(data: any, isPreview: boolean) {
    return axios
      .post(`${this.baseUrl}create_diagram_from_ebd_steps/`, data, {
        params: { isPreview }
      })
      .then(res => {
        return res.data;
      });
  }
  public async postExcelData(data: any) {
    try {
      const arrDiagrams = await axios.post(
        `${this.baseUrl}create_diagram_from_excel_upload/`,
        data
      );
      return arrDiagrams.data;
    } catch (err) {
      return new Error("something went wrong");
    }
  }
  public deleteDiagram(id: string) {
    return axios
      .delete(`${this.baseUrl}delete/${id}`)
      .then(res => {
        return res.data;
      })
      .catch((err: any) => {
        return err.data;
      });
  }
  public async getFilesForOverview(formatVersion: string | undefined) {
    try {
      const arrFiles = await axios.get(`${this.baseUrl}overview/`, {
        params: { formatVersion }
      });
      return arrFiles.data;
    } catch (err) {
      return [];
    }
  }
  public async getFormatVersions(): Promise<string[]> {
    try {
      const arrFormats = await axios.get(`${this.baseUrl}formats/`);
      // remove the diagrams without formatVersion
      if (arrFormats.data["NO_FORMAT_VERSION"]) {
        delete arrFormats.data["NO_FORMAT_VERSION"];
      }
      return arrFormats.data;
    } catch (err) {
      throw new Error("Fehler beim Laden der Formatversionen.");
    }
  }
  public async getRoles(): Promise<string[]> {
    try {
      const arrRoles = await axios.get(`${this.baseUrl}roles/`);
      return arrRoles.data;
    } catch (err) {
      throw new Error("Fehler beim Laden der prüfenden Rollen.");
    }
  }
  public async postUserData(userData: object) {
    try {
      const userInfo = await axios.post(`${this.baseUrl}user_form`, userData, {
        timeout: 8000
      });
      return userInfo.data;
    } catch (err) {
      return err.response;
    }
  }
  public async CheckUserConfirmation(email: string) {
    try {
      const userInfo = await axios.get(`${this.baseUrl}check_email`, {
        timeout: 8000,
        params: { email }
      });
      return userInfo.data;
    } catch (err) {
      return err.response;
    }
  }
  public async getDiagram(ebdKey: string, formatVersion: string) {
    const fv: string = formatVersion;
    try {
      const diagram = await axios.get(`${this.baseUrl}${ebdKey}`, {
        params: { fv }
      });
      return diagram.data;
    } catch (err) {
      return err.response;
    }
  }
}
