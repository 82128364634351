<template>
  <div id="app">
    <Navbar />
    <Cookie />
    <router-view />
    <footer id="footer" class="text-muted text-center pt-5">
      <p class="m0">
        ©

        <a class="colorLink" target="_blank" href="https://www.hochfrequenz.de"
          >2021 www.hochfrequenz.de</a
        >

        . Alle Rechte vorbehalten. | All rights reserved. |
        <router-link class="colorLink" to="/data-privacy"
          >Datenschutz</router-link
        >

        |
        <router-link to="/impressum" class="colorLink">Impressum</router-link>
      </p>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Cookie from "@/components/Cookie.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Cookie
  }
};
</script>

<style>
* {
  font-family: "Roboto Condensed", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Yanone Kaffeesatz", sans-serif !important;
  color: #7aab8a !important;
  text-transform: uppercase;
}
#footer {
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  background-color: #f3f1f6;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f3f1f6;
}
.colorLink {
  color: #7aab8a;
}
.colorLink:hover {
  color: #7aab8a;
}
.overview,
.Register,
.logingComponent {
  flex: 1 0 auto;
}
.btn-success {
  background-color: #15470f !important;
}
svg {
  max-width: 100%;
  height: auto;
}
</style>
