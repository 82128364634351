





































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["table"]
})
export default class TableCard extends Vue {}
