





























































































































// @ts-ignore
//eslint-disable-next-line
window.pdfjsWorker = require("pdfjs-dist/build/pdf.worker.js");
import { Component, Vue, Watch } from "vue-property-decorator";
import TableCrad from "./TableCard.vue";
import TableHeader from "./TableHeader.vue";
import analyze from "./helper/analyze";
import * as pdfjs from "pdfjs-dist";
import GeneratorService from "@/services/GeneratorService";

@Component({
  components: {
    TableCrad,
    TableHeader
  }
})
export default class Table extends Vue {
  public isPreview: boolean = true;
  public isError: boolean = false;
  public isSuccess: boolean = false;
  public successMessage: string = "";
  public alert: boolean = false;
  public errorMessage: string = "";
  public diagram: string = "";
  public showPages: boolean = false;
  public loading: boolean = false;
  public pdfData: any[] = [];
  public document: any = "";
  public pages: any = {
    pageFrom: 0,
    pageTo: 0
  };
  public tableCard: any = {
    title: "",
    title2: "",
    role: "",
    ebd_key: "",
    format_version: "",
    steps: []
  };
  public deleteDiagram() {
    this.diagram = "";
    this.isSuccess = false;
    this.successMessage = "";
  }
  public downloadSVG(evt: any) {
    const svgContent = document.getElementsByTagName("svg")[0].outerHTML,
      blob = new Blob([svgContent], {
        type: "image/svg+xml"
      }),
      url = window.URL.createObjectURL(blob),
      link = evt.target;

    link.target = "_blank";
    link.download = `${this.tableCard.ebd_key}.svg`;
    link.href = url;
  }
  public restartThePage() {
    location.reload();
  }
  public async onChange(event: any) {
    this.document = await pdfjs.getDocument(
      URL.createObjectURL(event.target.files[0])
    ).promise;
    this.showPages = await true;
  }
  public generate() {
    if (!this.document) return;

    analyze(
      this.document,
      parseInt(this.pages.pageFrom),
      parseInt(this.pages.pageTo)
    ).then((mapedData: any) => {
      this.pdfData = mapedData;
      mapedData.map((e: any) => {
        this.tableCard.steps.push({
          stepNumber: e.stepNumber,
          checkDescription: e.checkDescription,
          answerTrue: {
            nextStep: e.testResults.success.nextStep
              ? parseInt(e.testResults.success.nextStep)
              : null,
            code: e.testResults.success.code,
            note: e.testResults.success.note
          },
          answerFalse: {
            nextStep: parseInt(e.testResults.failure.nextStep)
              ? e.testResults.failure.nextStep
              : null,
            code: e.testResults.failure.code,
            note: e.testResults.failure.note
          }
        });
      });
    });
  }
  public addNewRow = (data: string) => {
    let prefillStepNumber: string = "";
    if (data === "note") {
      prefillStepNumber = "Hinweis";
    }
    this.tableCard.steps.push({
      stepNumber: prefillStepNumber,
      checkDescription: "",
      answerTrue: {
        code: "",
        note: "",
        nextStep: null
      },
      answerFalse: {
        code: "",
        note: "",
        nextStep: null
      }
    });
  };
  public submitData() {
    this.diagram = "";
    this.isError = false;
    this.errorMessage = "";
    if (!this.tableCard.ebd_key) {
      this.isError = true;
      this.errorMessage = "Der Diagrammname wird benötigt.";
      this.alert = true;
    } else if (
      this.tableCard.steps.length === 0 ||
      !this.tableCard.steps[0]["stepNumber"]
    ) {
      this.isError = true;
      this.errorMessage =
        "Für ein Diagram wird mindestens ein Prüfschritt mit Nummer benötigt.";
    } else if (!this.tableCard.role || !this.tableCard.format_version) {
      this.isError = true;
      this.errorMessage =
        "Die zu prüfende Rolle und die Formatversion werden benötigt";
      this.alert = true;
    } else {
      this.tableCard.steps = this.tableCard.steps.filter((step: any) => {
        return (
          (step["stepNumber"] !== "" || step["stepNumber"] !== "Hinweis") &&
          step["checkDescription"] !== ""
        );
      });
      if (!this.isError) {
        this.loading = true;
        const newGeneratorService = new GeneratorService();
        newGeneratorService
          .postPdfData(this.tableCard, this.isPreview)
          .then((response: any) => {
            this.diagram = response;
            this.loading = false;
            this.isError = false;
            this.isSuccess = true;
            this.successMessage = `Diagramm für ${this.tableCard.format_version} wurde im Overview unter ${this.tableCard.ebd_key} gespeichert.`;
            this.errorMessage = "";
          })
          .catch(error => {
            this.isError = true;
            this.isSuccess = false;
            this.errorMessage = `Ein Fehler ist aufgetreten, das Diagram konnte nicht generiert werden. ${error}`;
            this.loading = false;
          });
      }
    }
  }
}
