import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import VueGtag from "vue-gtag";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./components/css/main.css";
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_ANALYTICS_ID
    },
    appName: "EBD-Homepage",
    pageTrackerScreenviewEnabled: true
  },
  router
);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
