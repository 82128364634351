<template>
  <div id="Cookie">
    <b-container class="cookieBanner" fluid @click="showCookieModal">
      <span class="cookieText">Cookie-Einstellungen</span>
    </b-container>

    <!-- Cookie-Modal -->
    <b-modal id="cookieModal" centered>
      <template #modal-title>
        <span class="modalTitle">COOKIE-EINSTELLUNGEN</span>
      </template>

      <div class="d-block">
        <span>
          Wir verwenden sogenannte Cookies zur anonymen Erhebung von Statistiken
          sowie solche, die zur Ausspielung und Anzeige personalisierter Inhalte
          auch nach dem Besuch unserer Webseite eingesetzt werden können. Durch
          Cookie-Einstellungen können Sie selbst entscheiden, ob Sie diese
          Cookies zulassen möchten. Ihre Einwilligung können Sie jederzeit
          widerrufen und in den Cookie-Einstellungen entsprechend ändern. In
          ändern. In unseren
          <a class="colorLink" href="/data-privacy">Datenschutzhinweisen</a>
          finden Sie weitere entsprechende Informationen.
        </span>
        <b-form-checkbox class="modalSelection" size="lg" v-model="setAnalytics"
          >Analytics
        </b-form-checkbox>
      </div>

      <template #modal-footer>
        <b-button
          class="otherConfirmation"
          variant="light"
          @click="setCookieSelection"
          >AUSWAHL BESTÄTIGEN</b-button
        >
        <b-button class="cookieConfirmation" @click="setAllCookies"
          >ALLE AKZEPTIEREN</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  components: {},
  mounted: function() {
    this.setAnalytics = true;
  },
  data: function() {
    return { setAnalytics: [] };
  },
  methods: {
    showCookieModal() {
      this.$bvModal.show("cookieModal");
    },
    setCookieSelection() {
      if (this.setAnalytics) {
        this.$gtag.optIn();
      } else {
        this.$gtag.optOut();
      }
      this.$bvModal.hide("cookieModal");
    },
    setAllCookies() {
      this.setAnalytics = true;
      this.$$gtag.optIn();
      this.$bvModal.hide("cookieModal");
    }
  }
};
</script>

<style scoped>
#cookieModal {
  background-color: #ffffff;
}
.cookieBanner {
  width: initial;
  background-color: #91a9a7;
  display: block;
  border-style: solid;
  border-color: #ffffff;
  border-bottom: 0;
  position: fixed;
  left: 20px;
  bottom: 0;
  margin: 0;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  box-sizing: border-box;
}
.cookieBanner:hover {
  background-color: #7aab8a;
}
.cookieText {
  color: #ffffff;
  background-color: #91a9a7;
  font-size: 18px;
  font-family: "Yanone Kaffeesatz", sans-serif !important;
}
.cookieConfirmation {
  color: #ffffff !important;
  background-color: #91a9a7;
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  border: none;
  border-radius: 0px 0px 0px 0px;
  border-style: solid;
}
.cookieConfirmation:hover {
  background-color: #7aab8a;
  border-color: #ffffff;
  padding: 10px 10px 10px 10px;
  border-radius: 0px 0px 0px 0px;
  color: #ffffff !important;
}
.otherConfirmation {
  color: #91a9a7 !important;
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  border: none;
  border-radius: 0px 0px 0px 0px;
}
.otherConfirmation:hover {
  color: #ffffff !important;
  background-color: #91a9a7;
  border-color: #91a9a7;
}
.modalTitle {
  font-size: 30px;
  font-family: "Yanone Kaffeesatz", sans-serif !important;
}
.modalSelection {
  margin: 2em;
}
</style>
