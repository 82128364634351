<template>
  <div id="Navbar">
    <b-container fluid>
      <b-navbar class="navbarMain" toggleable="md">
        <b-navbar-brand>
          <a target="_blank" href="https://www.hochfrequenz.de">
            <img
              alt="hochfrequenz"
              class="logo"
              :src="require('./assets/hf_logo.svg')"
          /></a>
        </b-navbar-brand>
        <b-button id="fontDesign" class="overviewLink my-auto" to="/"
          >Entscheidungsbaumdiagramme</b-button
        >
        <b-button
          class="ediLink"
          target="_blank"
          href="https://www.edi-energy.de/index.php?id=38"
          id="fontDesign"
        >
          EDI@Energy</b-button
        >
        <div v-if="userRole === 'Admin'">
          <!-- disbale till we have an auth0 registration

        <b-navbar-brand to="/upload-pdf">Erstellen</b-navbar-brand>
        <b-navbar-brand to="/upload">Upload</b-navbar-brand>
        <b-navbar-brand to="/delete">Löschen</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        --></div>
        <b-navbar-nav class="ml-auto"
          ><div v-if="userRole">
            <b-button
              id="fontDesign"
              class="customerButton"
              @click="logoutHandler"
              to="/login"
              >Abmelden</b-button
            >
          </div>
          <div v-else>
            <b-button id="fontDesign" class="customerButton" to="/register"
              >Registrieren</b-button
            >
            <b-button id="fontDesign" class="customerButton" to="/login"
              >Anmelden</b-button
            >
          </div>
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  components: {},
  mounted: function() {
    this.userRole = localStorage.getItem("userRole");
  },

  data: function() {
    return { userRole: "" };
  },
  methods: {
    logoutHandler() {
      this.userRole = "";
      localStorage.clear();
    }
  }
};
</script>

<style scoped>
#Navbar {
  margin: 20px 0;
  display: "flex";
}
#fontDesign {
  font-family: "Yanone Kaffeesatz", sans-serif !important;
  font-size: 30px;
  color: #91a9a7;
  text-transform: uppercase;
}
.logo {
  width: 300px;
  margin-right: 2.5em;
}
.overviewLink {
  color: #91a9a7 !important;
  background-color: #f3f1f6;
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  border: none;
  border-radius: 0px 0px 0px 0px;
  border-style: solid;
}
.overviewLink:hover {
  background-color: #7aab8a;
  border-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  color: #ffffff !important;
}
.overviewLink:focus {
  outline: none;
  box-shadow: none;
  background-color: #7aab8a;
  color: #ffffff !important;
}
.ediLink {
  background-color: #f3f1f6;
  color: #91a9a7;
  padding: 10px 10px 10px 10px;
  margin-left: 1.5em;
  border: none;
}
.ediLink:hover {
  background-color: #f3f1f6;
  border: none;
  color: #7aab8a;
  border-bottom: 1px solid #7aab8a;
}
.ediLink:focus {
  outline: none;
  box-shadow: none;
  background-color: #f3f1f6;
}
.customerButton {
  background-color: #f3f1f6;
  border: none;
  border-radius: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  border-color: #f3f1f6;
  border-style: solid;
}
.customerButton:hover {
  background-color: #7aab8a;
  border-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
  color: #ffffff !important;
}
.customerButton:focus {
  outline: none;
  box-shadow: none;
  background-color: #7aab8a;
  color: #ffffff !important;
}
</style>
